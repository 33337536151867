/* common.css */

.App {
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin: 1rem;
   box-sizing: border-box;
}

/* need to remove*/
.container {
   /* flex: 0; */
   width: 100%;
   max-width: 100%;
}

.layout {
   display: flex;
}

.loding-screen {
   position: absolute;
   background: #ffffffcc;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

/* .cursor-pointer {
   cursor: pointer;
   padding: 0.6rem 1rem;
} */

.parent-container {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   height: 100vh;
}

.h2 {
   color: #9e9e9e;
   font-feature-settings:
      "clig" off,
      "liga" off;
   font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0.15px;
   margin-left: 30px;
}

.row {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.column-title {
   text-align: center;
   color: #667085;
   font-family: inherit;
   font-size: 0.9rem;
   font-style: normal;
   font-weight: 600;
   line-height: 18px;
   align-items: center;
   border: 0px;
   outline: none;
   cursor: pointer;
}

/* Linked start */

.linked-tc-modal-box-header {
   height: 66px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #f5f5f5;
}

.linked-tc-modal-box-header h4 {
   font-size: 14px;
   font-weight: 500;
   text-align: left;
   margin: 0 35px;
}

.linked-tc-modal-box-header img {
   padding: 7px;
   background-color: #f5f5f5;
   border-radius: 8px;
   margin: 0 20px;
}

.linked-tc-modal-box-search {
   height: 77px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.linked-tc-modal-box-search h4 {
   @apply text-primary;
   /* color: #1d86ff; */
   font-size: 14px;
   font-weight: 500;
   margin: 0 28px;
   text-align: left;
}

/* .linked-tc-modal-box-search div {
   width: 224px;
   height: 36px;
   border-radius: 4px;
   border: 1px solid #e0e0e0;

   display: flex;
   align-items: center;
   margin-right: 23px;
}

.linked-tc-modal-box-search div img {
   margin-left: 12px;
}

.linked-tc-modal-box-search div input {
   width: 100%;
   outline: none;
   border: none;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.25px;
}

.linked-tc-modal-box-search div input::placeholder {
   color: #9e9e9e;
   font-size: 14px;
} */

.linked-tc-modal-box-footer {
   position: relative;
   height: 62px;
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   border-top: 1px solid #f5f5f5;
   gap: 1.3rem;
}

.linked-tc-modal-box-footer .linked-tc-modal-box-footer-item {
   cursor: pointer;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 4px;
   font-size: 12px;
   font-weight: 400;
   text-align: center;
   border: 1px solid #eeeeee;
}

.linked-tc-modal-box-footer div:first-child {
   width: 79px;
}

.linked-tc-modal-box-footer div:nth-child(2) {
   background-color: #0036af;
   color: white;
   width: 120px;
   margin-right: 23px;
}

/* Linked start */

.plus-span {
   font-size: 20px;
}

.pagination-info {
   background-color: #fff;
   height: 65px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: rem;
}

.text-row-key {
   font-family: Roboto;
   font-size: 14px;
   font-weight: 700;
   line-height: 20px;
   letter-spacing: 0.25px;
   text-align: left;
   color: #1d86ff;
}

.text-row {
   color: #667085;
   height: 1.8rem;
}

.pagination-cont {
   margin-top: 1.3rem;
   height: 1rem;
   background-color: #ffffff;
}

.ant-table-thead .ant-table-cell .column-title {
   cursor: pointer;
}

.view-linked-tests-row {
   @apply text-primary;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 2px;
   /* color: #1d86ff; */
   cursor: pointer;
}

.view-linked-tests-row span {
   height: 20px;
   min-width: 20px;
   box-sizing: border-box;
   padding: 1px;
   border-radius: 30px;
   border: 1px solid #1d86ff;
   color: #1d86ff;
   display: flex;
   align-items: center;
   justify-content: center;
}

.modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   backdrop-filter: blur(5px);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.modal-content {
   background-color: white;
   border-radius: 8px;
   max-width: 80%;
   min-width: 50rem;
   max-height: 80%;
   overflow: hidden;
   position: relative;
}

.error {
   color: red;
   display: flex;
   margin-top: 3px;
   font-weight: 400;
   font-size: 0.9rem;
}

.r {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   gap: 12px;
   font-size: 12px;
   color: white;
   background: #0036af;
   border: none;
   box-shadow: none;
   outline: none;
   border-radius: 4px;
   padding: 10px 9px;
   cursor: pointer;
}

/* Table start */

.table {
   min-height: 100%;
   height: auto;
   width: auto;
   overflow: visible;
   border-collapse: collapse;
}

.table th {
   padding: 0;
   height: 44px;
}

.table td {
   text-align: center;
   color: #667085;
   font-size: 15px;
}

.table td:first-child {
   color: #101828;
   font-weight: 500;
}

.table tr {
   height: 72px;
}

.table tr:hover {
   background-color: #f5f5f5;
}

.table .ant-table-thead > tr > th {
   background-color: #ffffff;
   max-height: 44px;
   padding: 0;
}

.table .ant-table-thead > tr {
   background-color: white;
}

.table-button {
   background-color: transparent;
   padding: 0;
   border: none;
   width: 20px;
   height: 20px;
   display: none;
   justify-content: center;
   align-items: center;
}

.table-button:hover {
   cursor: pointer;
}

.table-cont {
   background-color: #ffffff;
}

.table-body .table tr {
   height: 42px;
}

/* Table end */

/* Input start */

.input-field {
   display: flex;
   width: 380px;
   padding: 10px;
   align-items: center;
   gap: 10px;
   border-radius: 4px;
   border: #e0e0e0;
   outline: none;
   box-shadow: none;
   border: 1px solid #cec7c7;
   color: black;
}

.input-field::after {
   border: #e0e0e0;
}

/* Input end */

/* Button start */

.button-style {
   color: #67707f;
   height: 36px;
   border: 1px solid #d0d5dd;
   border-radius: 8px;
   padding-left: 12px;
   padding-right: 12px;
   background-color: transparent;
   font-size: 1.03rem;
}

.button-style:hover {
   color: #fff;
   height: 36px;
   border: 1px solid #d0d5dd;
   border-radius: 8px;
   padding-left: 12px;
   padding-right: 12px;
   background-color: #979797;
   cursor: pointer;
}

.button {
   border-radius: 12px;
   border: #1d86ff;
   background: #ddedff;
   box-shadow: 0px 0px 4px 0px rgba(0, 130, 203, 0.3);
   display: flex;
   padding: 8px;
   justify-content: center;
   align-items: center;
   gap: 30px;
   color: #1d86ff;
   text-align: center;
   font-feature-settings:
      "clig" off,
      "liga" off;
   font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.4px;
}

/* Button end */

/* Module start */

/* Module end */

/* Forms start */

.forms {
   @apply border border-secondary-300 py-xs px-3 text-md font-medium flex items-center gap-0 shrink-0 rounded-md outline-none pl-8 bg-white shadow-none focus:border-secondary-500;
   /* display: flex; */
   width: 224px;
   height: 16px;
   /* padding: 10px 12px; */
   /* align-items: center; */
   /* gap: 8px; */
   /* flex-shrink: 0; */
   /* border-radius: 4px;  */
   /* border: 1px solid #cec7c7; */
   /* outline: none; */
   /* box-shadow: none; */
   /* padding-left: 40px; */
   /* background: #fff; */
}

/* .forms:focus {
   @apply border-secondary-500;
} */

/* Forms end */

/* Search start */

.total-upper-search-container {
   display: flex;
   flex-direction: row;
}

.upper-search-container {
   display: flex;
   gap: 1rem;
   align-items: center;
}

.upper-search-container .search-container {
   position: relative;
}

.upper-search-container .search-container .search-container-notification {
   position: absolute;
   top: 39px;
   left: 0;
}

.upper-search-container .search-container .search-container-notification p {
   color: red;
   font-size: 12px;
}

.search-container {
   position: relative;
}

.search-icon {
   position: absolute;
   top: 50%;
   left: 10px;
   transform: translateY(-50%);
}

/* Search end */

/* Select start */

.ant-select-selector {
   @apply !border-secondary-300 !shadow-none !rounded-md;
}

.ant-select-selector .ant-select-selection-wrap {
   @apply h-full;
}

.select-input.ant-select {
   @apply border border-secondary-300 focus:border-secondary-500 !h-9 !outline-none bg-white rounded-md;
   display: flex;
   width: 121px;
   height: 36px;
   /* padding: 10px 12px; */
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   /* border: 1px solid #cec7c7; */
   outline: none;
   box-shadow: none;
   /* border-radius: 4px; */
}

.ant-select-dropdown
   .ant-select-item-option-active.ant-select-item-option-selected {
   @apply !bg-secondary-300;
}

.ant-select-dropdown .ant-select-item-option-selected {
   @apply !bg-secondary-400;
}

.select-input.ant-select .ant-select-selector {
   background-color: transparent;
   border: none;
}

.select-input.ant-select .ant-select-arrow {
   color: #9e9e9e;
}

/* Select end */

/* container start */

/* .values-of-suggestions { */
/* position: relative;
    top: 50px; */
/* } */

.container-of-values,
.react-autosuggest__suggestions-container {
   position: absolute;
   width: 290px;
   /* height: 280px; */
   height: auto;
   background-color: #ffffff;
   box-shadow: 0px 4px 6px 0px #00000026;
   border-radius: 8px;
   overflow-y: auto;
   scrollbar-width: none;
   -ms-overflow-style: none;
}

.container-of-values {
   width: auto;
   min-height: 70px;
   min-width: 290px;
   height: auto;
   z-index: 2;
}

.container-of-values button {
   background-color: transparent;
   border: none;
}

.container-of-values.additional {
   padding-bottom: 8px;
   width: auto;
   min-height: 70px;
   min-width: 217px;
   height: auto;
   left: auto;
   z-index: 1;
   overflow: auto;
   max-height: 130px;
}

/* container end */

/* Row start */

.additional-row {
   display: flex;
   padding: 8px 16px 0px 16px;
}

.additional-row.extra_space {
   padding: 4px 4px 4px 16px;
}

.additional-row.searched {
   background-color: rgba(153, 26, 231, 0.05);
}

.additional-row img {
   margin-right: 8px;
}

/* Row end */

/* Video start */

.video-container {
   /* padding-bottom: 56.25%; */
   margin: 3.5rem 1.5rem;
   position: relative;
}

.video-container iframe,
.video-container video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: auto;
   padding: 10px;
   box-sizing: border-box;
}

/* Video end */

/* Response status start */
.response-status {
   position: absolute;
   top: 0px;
   right: 0px;
   display: flex;
   flex-direction: row-reverse;
   align-items: center;
   gap: 1rem;
}

.response-status div {
   display: flex;
   gap: 0.3rem;
   font-weight: 400;
   font-size: 12px;
   color: #9e9e9e;
   letter-spacing: 0.4px;
}

.response-status div p {
   @apply text-primary m-0;
   /* color: #0036af; */
   /* margin: 0; */
}

/* Response status end */

/* JS code editor start */

.code-editor {
   font-family: "Fira code", "Fira Mono", monospace;
   font-size: 14px;
   width: 100%;
   overflow: hidden;
   background-color: #fafafa;
   outline: none !important;
   outline-color: transparent !important;
   min-height: 500px;
   border: 2px solid #8888880f;
   border-radius: 8px;
}

.code-editor textarea {
   outline: 0;
}

/* JS code editor end */

/* Others*/

.page-info {
   @apply text-sm text-secondary-900 font-semibold;
   margin-left: 1rem;
   /* font-size: 1.01rem; */
   /* font-size: 14px; */
   /* color: #000000; */
   /* font-weight: 700; */
}

.page-container {
   display: flex;
   flex-direction: column;
   gap: 1.2rem;
   background-color: #fafafa;
   padding: 1rem 2rem;
   /* min-height: calc(100vh - 96px); */
   height: 100%;
   width: 100%;
   box-sizing: border-box;
}

.prev-next-button {
   margin-right: 2rem;
   display: flex;
   gap: 1rem;
   justify-content: center;
   font-size: 1.01rem;
   font-weight: 500;
}

.d-flex {
   display: flex;
}

.filt {
   display: flex;
   width: 121px;
   height: 36px;
   padding: 10px 12px;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   outline: none;
   box-shadow: none;
   border: 1px solid #cec7c7;
   color: #9e9e9e;
   background-color: #fff;
   justify-content: space-between;
   border-radius: 4px;
}

.column-data {
   text-align: center;
}

.arrowEC {
   padding: 2px;
   cursor: pointer;
}

.arrowEC:hover {
   background-color: #fafafa;
   cursor: pointer;
   border-radius: 2px;
}

.text-container {
   display: flex;
   flex-direction: column;
}

.c-img {
   height: 18px;
   margin-right: 10px;
   cursor: pointer;
}

.main-heading {
   text-align: center;
   font-size: 48px;
   font-weight: 400;
   font-family: "Roboto", sans-serif;
   color: white;
}

.sub-heading {
   text-align: center;
   font-size: 16px;
   font-weight: 400;
   font-family: "Roboto", sans-serif;
   color: white;
}

.welcome-message {
   text-align: center;
   font-size: 20px;
   font-family: "Roboto", sans-serif;
   font-weight: 500;
   margin-bottom: 25px;
   color: #212121;
}

.logo_and_texts {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 10px;
}

.detail-heading {
   text-align: center;
   font-size: 14px;
   font-weight: 400;
   font-family: "Roboto", sans-serif;
   color: #616161;
}

.loading-content {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.icon {
   padding-right: 8px;
}

/* Tab start */

.tab {
   padding: 10px;
}

.tab .ant-tabs-tab-btn {
   @apply !text-secondary-600;
}

.tab .ant-tabs-tab-btn:hover {
   @apply !text-secondary-700;
}

.tab .ant-tabs-tab-active .ant-tabs-tab-btn {
   @apply !text-primary;
}

.ant-tabs .ant-tabs-ink-bar {
   @apply bg-primary;
}

.active-tab {
   color: #0036af;
}

.other-tab {
   color: #9e9e9e;
}

/* Tab end */

.step-dropdown {
   position: absolute;
   top: 40px;
   left: 0px;
   width: 290px;
   max-height: 250px;
   background-color: #ffffff;
   box-shadow: 0px 4px 6px 0px #00000026;
   border-radius: 8px;
   overflow-y: auto;
   scrollbar-width: none;
   -ms-overflow-style: none;
   padding: 0.5rem;
   /* max-height: 85px; */
   box-sizing: border-box;
   z-index: 10;
}

.script-modal .ant-modal-content {
   padding: 0;
}

.script-modal .ant-modal-close {
   display: none;
}

.input-error {
   border: 1px solid red;
}

.value {
   cursor: pointer;
   color: #e29500;
   padding: 5px;
   display: inline-block;
}

.dropdown {
   position: absolute;
   border: 1px solid #ddd;
   background: #fff;
   padding: 10px;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.refresh-icon {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 22px;
   cursor: pointer;
   opacity: 0.5;
}

.footer-buttons {
   display: flex;
   gap: 10px;
}

.close-button {
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 24px;
   background: none;
   border: none;
   cursor: pointer;
}

.right-side {
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   padding-left: 1%;
   background-color: #fafafa;
}

/* .col-max-width {
  max-width: 320px;
} */

.col-max-width {
   max-width: 320px;
}

.disabled {
   cursor: not-allowed;
   pointer-events: none;
   user-select: none;
   opacity: 0.6;
}
/* ---------------------------UTILITY------------------------------- */

/* Scrollbar Styling */
.scrollar::-webkit-scrollbar {
   width: 8px;
   height: 8px;
}

.scrollar::-webkit-scrollbar-thumb {
   background: #f5f5f5;
   border-radius: 4px;
   transition: background-color 0.3s ease;
}

.scrollar::-webkit-scrollbar-thumb:hover {
   background: #dddddd;
}

.scrollar::-webkit-scrollbar-track {
   background: #ffffff;
   border-radius: 4px;
}

/* .scroll-modal-container {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
} */

/*  */
.loading-card-content {
   color: transparent;
   background: linear-gradient(
      110deg,
      #ffffff 18%,
      rgba(144, 238, 144, 0) 40%,
      rgba(144, 238, 171, 0.15) 50%,
      rgba(144, 238, 144, 0) 60%,
      #ffffff 82%
   );
   background-size: 200% 100%;
   animation: loading-card 1.8s ease-in-out infinite;
}

@keyframes loading-card {
   0% {
      background-position: 200% 0;
   }
   100% {
      background-position: -200% 0;
   }
}

/* #F5F5F5 */

.minimize-modal {
   width: 36px;
   height: 36px;

   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 8px;

   cursor: pointer;
}
.minimize-modal:hover {
   background-color: #f5f5f5;
}
.minimize-modal .minimize {
   background-color: #bdbdbd;
   width: 16px;
   height: 2px;
}

.T-button {
   width: 25px;
   height: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 4px;
   background-color: #96e6a7;
   color: white;
}

.highlight {
   outline: 2px solid rgb(240, 152, 21);
}

.disable-overlay {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   background-color: rgba(255, 255, 255, 0.34);
   cursor: not-allowed;
}

.input_textcasemodal {
   @apply border border-secondary-300 focus:border-secondary-500 rounded-md px-3 py-2 mt-2 text-secondary-900 text-md font-medium;
   width: 100%;
   height: 2.5rem;
   box-sizing: border-box;
   /* margin-top: 0.7rem; */
   /* border: 1px solid grey; */
   /* border-radius: 5px; */
   /* font-size: 19px; */
   /* padding: 2px 5px; */
   /* padding-bottom: 0; */
}

.module-selected-label {
   @apply border border-secondary-300 focus:border-secondary-500 rounded-md text-md text-secondary-900 font-medium mt-2;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   height: 2.5rem;
   padding: 0px 10px;
   box-sizing: border-box;
   cursor: pointer;
   /* margin-top: 0.7rem; */
   /* border: 1px solid grey; */
   /* border-radius: 5px; */
   /* font-size: 16px; */
}

.date-button-filter-cont {
   display: flex;
   gap: 1rem;
   align-items: center;
   height: 2.4rem;
   padding: 8px 10px;
   min-width: 80px;
   box-sizing: border-box;
   /* border-radius: 4px; */
   /* border: 1px solid #cec7c7; */
}

.toggle-filter-button {
   display: flex;
   gap: 0.5rem;
   align-items: center;
}

.field-sizing-content {
   field-sizing: content;
}

/* Type icons styles */

.f-icon {
   background: rgb(0, 142, 161);
   color: white;
   border-radius: 100%;
   cursor: default;
   font-size: 0.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 15px;
   height: 15px;
   flex-shrink: 0;
   padding-top: 0.6px;
}

.T-icon {
   background: #96e6a7;
   color: white;
   border-radius: 100%;
   cursor: default;
   font-size: 0.6rem;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   width: 15px;
   height: 15px;
   flex-shrink: 0;
}

.T-text {
   color: #50d36c;
}

.G-icon {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   min-width: 15px !important;
   height: 15px !important;
   border-radius: 50%;
   color: white;
   font-size: 10px;
   line-height: 0;
   font-weight: 400;
   /* background-color: #991AE7; */
   background-color: #d198f4;
}

.G-text {
   color: #991ae7;
}

.L-icon {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   min-width: 15px !important;
   height: 15px !important;
   border-radius: 50%;
   color: white;
   font-size: 10px;
   line-height: 0;
   font-weight: 400;
   /* background-color: #991AE7; */
   background-color: #96a7e6;
}

.L-text {
   color: #96a7e6;
}

/* ANT UI styles*/

.ant-switch {
   @apply bg-secondary-400;
}

.ant-switch:hover:not(.ant-switch-disabled) {
   @apply bg-secondary-500;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
   @apply bg-primary-600;
}

.ant-switch.ant-switch-checked {
   @apply bg-primary-500;
}

.ant-picker-outlined:focus-within {
   @apply border-secondary-400 shadow-none;
}

.ant-picker-outlined:hover {
   @apply border-secondary-500;
}

.ant-picker-range .ant-picker-active-bar {
   @apply bg-primary;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-today
   .ant-picker-cell-inner::before {
   @apply border-primary;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-disabled
   )
   .ant-picker-cell-inner {
   @apply bg-primary;
}

.ant-btn-variant-solid {
   @apply bg-primary;
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover {
   @apply bg-primary-600;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-disabled
   )
   .ant-picker-cell-inner {
   @apply !bg-primary;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
      .ant-picker-cell-disabled
   ):before {
   @apply bg-secondary-300;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-disabled
   ):before {
   @apply bg-secondary-300;
}

.ant-picker-dropdown
   .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-disabled
   ):before {
   @apply bg-secondary-300;
}

.ant-picker-dropdown
   .ant-picker-time-panel-column
   > li.ant-picker-time-panel-cell-selected
   .ant-picker-time-panel-cell-inner {
   @apply bg-secondary-300;
}

.ant-pagination .ant-pagination-item-active a, .ant-pagination .ant-pagination-item-active a:hover {
   @apply text-primary;
}

.ant-pagination .ant-pagination-item-active a:hover {
   @apply text-primary-600;
}

.ant-pagination .ant-pagination-item-active, .ant-pagination .ant-pagination-item-active:hover {
   @apply border-primary;
}

.ant-pagination .ant-pagination-item-active:hover {
   @apply border-primary-600;
}

.ant-spin-dot-item {
   @apply !bg-primary;   
}
/* From src/pages/SuiteReports/report.css */

/* .stop {
  margin-bottom: 20px;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  background-color: #b91c1c;
  display: flex;
  justify-content: center;
} */
/* 
.TD {
  font-weight: bold;
} */

/* .int {
  color: #0036af;
  display: flex;
  gap: 10px;
  font-weight: 400;
} */

/* .TT {
  color: #667085;
} */

/* .subT {
  color: #667085;
  margin-left: 20px;
} */

/* From src/pages/SuiteReports/SuiteTestingDetails/TestingDetails.css */
/* .reports-testing {
  display: flex;
}

.reports-testing .box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reports-testing .main-box {
  padding: 10px;
  margin: 10px;
  background: #fafafa;
  border-radius: 5px;
  width: auto;
  justify-content: center;
  align-items: center;
}

.reports-testing .main-box .testingAnalytics {
  background-color: white;
  margin-top: 12px;
  border-radius: 5px;
  width: px;
  min-height: 250px;
} */

/* .testingAnalyticsNavbar {
  padding: 7px;
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
} */

/* .testingAnalyticsNavbar .tab-mode {
  justify-content: center;
} */

/* .tab-mode img {
  padding-left: 7px;
} */

/* .arrowEC {
  padding: 2px;
  cursor: pointer;
}

.arrowEC:hover {
  background-color: #fafafa;
  cursor: pointer;
  border-radius: 2px;
} */

/* .testingAnalytics .testingAnalyticsDetails {
  margin-top: 10px;
  max-height: calc(100vh - 13.5rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.testingAnalytics .testingAnalyticsDetails::-webkit-scrollbar {
  padding: 1rem 3rem;
  width: 8px;
}

.testingAnalytics .testingAnalyticsDetails::-webkit-scrollbar-thumb {
  background-color: #e0e7f5;
  border-radius: 6px;
  padding: 1rem 3rem;
}

.testingAnalytics .testingAnalyticsDetails .input-form {
  border: 0;
  padding: 1px;
  min-width: 144px;
  border-radius: 2px;
  color: #e0e0e0;
  pointer-events: none;
} */

/* .statusControl {
  background-color: #fafafa;
  padding: 8px 10px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: "10px";
} */

/* .statusControl button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .statusControl p {
  color: #bdbdbd;
  font-size: 10px;
} */

/* .testcase-group {
  display: flex;
  border: 1px solid #f5f5f5;
} */

/* .mode-container {
  position: relative;
  padding: 10px;
} */

/* .icon-style {
  position: sticky;
  top: 10px;
  height: 12px;
  width: 12px;
} */

/* From src/pages/suites/Suites.css */
/* .test-case-name,
.test-case-title {
  display: block;
  cursor: pointer;
  padding-left: 50%;
  width: 50%;
} */

/* .highlight-row td {
  background: #e6f4ff !important;
}

.highlight-row td input[type="checkbox"] + span:before {
  color: #1677ff;
  font-weight: bold;
  width: 5.7142857142857135px;
  height: 9.142857142857142px;
  border-bottom: 2px solid #1677ff;
  border-right: 2px solid #1677ff;
  border-top: 0;
  content: "";
  display: inline-block;
  transform: rotate(45deg);
} */

/* .suite-platform {
  display: flex;
  gap: 1rem;
  padding-bottom: 10px;
} */

/* .sutie-platform-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
} */

/* .modal-platform-browser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  border-radius: 12px;
  background: #f4f4f4;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}

.modal-platform-browser.selected {
  background: rgb(224, 231, 245);
}

.modal-platform-browser.selected span {
  color: #555555;
} */

/* From src/pages/Testcase/testcase.css */

/* .module-filter-container div {
  line-height: 1;
} */

/* .module-name-container {
  display: flex;
  align-items: center;
} */

/* .intermediate-click-handler {
  display: flex;
  gap: 0.5rem;
} */

/* .filter-date-component {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/* .testcase-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background-color: #fafafa;
  padding: 1rem 2rem;
  min-height: calc(100vh - 96px);
} */

/* .testcase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .testcase-body {
  display: flex;
  width: 100%;
  background-color: #ffffff;
}

.testcase-body .table tr {
  height: 42px;
} */

/* From src/pages/Reports/TestingDetails/TestingDetails.css */
/* .reports-testing {
  display: flex;
}

.reports-testing .box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reports-testing .main-box {
  padding: 10px;
  margin: 10px;
  background: #fafafa;
  border-radius: 5px;
  width: auto;
  justify-content: center;
  align-items: center;
}

.reports-testing .main-box .testingAnalytics {
  background-color: white;
  margin-top: 12px;
  border-radius: 5px;
  width: px;
  min-height: 250px;
} */

/* .testingAnalyticsNavbar {
  padding: 7px;
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 3.75rem;
  align-items: center;
} */

/* .testingAnalyticsNavbar .tab-mode {
  justify-content: center;
} */

/* .tab-mode img {
  padding-left: 7px;
} */

/* .testingAnalytics .testingAnalyticsDetails {
  margin-top: 10px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.testingAnalytics .testingAnalyticsDetails::-webkit-scrollbar {
  padding: 1rem 3rem;
  width: 8px;
}

.testingAnalytics .testingAnalyticsDetails::-webkit-scrollbar-thumb {
  background-color: #e0e7f5;
  border-radius: 6px;
  padding: 1rem 3rem;
}

.testingAnalytics .testingAnalyticsDetails .input-form {
  border: 0;
  padding: 1px;
  min-width: 144px;
  border-radius: 2px;
  color: #e0e0e0;
  pointer-events: none;
} */

/* .statusControl {
  background-color: #fafafa;
  padding: 8px 10px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: "10px";
} */

/* .statusControl button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .statusControl p {
  color: #bdbdbd;
  font-size: 10px;
} */

/* From src/pages/Reports/TestingDetails/components/TreeViewReport.css */
/* .tv-toggle-wrap {
  margin-left: 20px;
} */

/* .tv-toggle-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.45rem;
  height: 1.4rem;
} */

/* .tv-border-bottom {
  border-bottom: 1px solid #e0e0e0;
} */

/* .tv-label-type {
  display: inline-block;
  border-radius: 0.2rem;
  text-transform: capitalize;
  padding: 0.2rem 0.7rem;
  font-size: 12px;
  font-weight: 700;
} */
/* 
.tv-arrow-btn {
  cursor: pointer;
  width: 0.65rem;
  height: 0.65rem;
  object-fit: contain;
  filter: opacity(0.4);
} */

/* .tv-status-icon {
  height: 0.85rem;
  width: 0.85rem;
  object-fit: fill;
} */

/* .tv-label-name {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
} */

/* .tv-step-container {
  min-height: 100px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.2rem 0.5rem 0.2rem 3em;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
} */

/* .tv-step-data {
  display: flex;
  align-items: center;
  gap: 0.4rem;
} */

/* .tv-variable-icon {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 500;
} */

/* .tv-g-icon {
  background-color: #991ae773;
  color: #ffffff;
} */

/* .tv-l-icon {
  background-color: #97a7e6;
  color: #ffffff;
} */

/* .tv-f-icon {
  background-color: #008ea1;
  color: #ffffff;
} */

/* .tv-step-value {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
} */

/* .tv-text-violet {
  color: #991ae7;
} */

/* .tv-text-blue {
  color: #96a7e6;
} */

/* .tv-text-cyan {
  color: #008ea1;
} */

/* .tv-value-wrap {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
} */

/* .tv-step-details {
  display: flex;
  align-items: center;
  gap: 0.4rem;
} */

/* .tv-group-instr {
  align-items: center;
  background: #1d86ff1a;
  border-radius: 0.2rem;
  color: #1d86ff;
  display: inline-flex;
  padding: 0.2rem 0.7rem;
} */

/* .tv-api-instr {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.tv-api-instr span {
  color: #1d86ff;
} */

/* .tv-resolved-data {
  display: flex;
} */

/* .system-mode-button {
  padding: 5px;
  border-radius: 5px;
}

.system-mode-button:hover {
  border-radius: 5px;
  background-color: #e0e7f5;
  cursor: pointer;
} */

/* .testingAnalyticsControlBar {
  padding: 7px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
} */

/* .system-mode-button {
  padding: 5px;
  border-radius: 5px;
}

.system-mode-button:hover {
  border-radius: 5px;
  background-color: #e0e7f5;
  cursor: pointer;
} */

/* .testingAnalyticsControlBar {
  padding: 7px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
} */
